<template>
  <!-- <div v-if="Object.keys(profileData).length" id="user-profile"> -->
  <div id="user-profile">
    <!-- <profile-header :header-data="profileData.header" /> -->
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <!-- <b-col lg="3" cols="12" order="2" order-lg="1">
          <profile-about :about-data="profileData.userAbout" />
          <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" />
        </b-col> -->
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col xl="3" lg="3" sm="12"></b-col>
        <b-col xl="6" lg="6" sm="12" cols="12" order="1" order-lg="2">
          <profile-post v-for="(posts, index) in items" :key="index" :posts="posts" />
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <!-- <b-col lg="3" cols="12" order="3">
          <profile-latest-photos :latest-images="profileData.latestPhotos" />
          <profile-suggestion :suggestions="profileData.suggestions" />
          <profile-polls :polls-data="profileData.polls" />
        </b-col> -->
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <b-col cols="12" order="4">
          <profile-bottom @loadmore="loadmore()" v-if="items.length != 0" />
          <h3 v-else class="text-center mt-2">No Posts Found</h3>
        </b-col>
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol, BPagination } from "bootstrap-vue";

import ProfileHeader from "./ProfileHeader.vue";
import ProfileAbout from "./ProfileAbout.vue";
import ProfileSuggestedPages from "./ProfileSuggestedPages.vue";
import ProfileTwitterFeed from "./ProfileTwitterFeed.vue";
import ProfilePost from "./ProfilePost.vue";
import ProfileLatestPhotos from "./ProfileLatestPhotos.vue";
import ProfileSuggestion from "./ProfileSuggestion.vue";
import ProfilePolls from "./ProfilePolls.vue";
import profileBottom from "./profileBottom.vue";

/* eslint-disable global-require */
export default {
  props: ["id", "userID"],
  components: {
    BRow,
    BCol,
    BPagination,

    ProfileHeader,
    ProfileAbout,
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePost,
    ProfileLatestPhotos,
    ProfileSuggestion,
    ProfilePolls,
    profileBottom,
  },
  data() {
    return {
      profileData: {},
      items: [],
      page: 1,
    };
  },
  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
      // console.log(this.profileData);
    });
    console.log(this.$route.params.id, this.userID);
    this.LoadData();
  },
  methods: {
    loadmore() {
      console.log("djdsjd");
      this.page += 1;
      this.LoadData();
    },
    LoadData() {
      if (this.$route.params.id == 0) {
        var axios = require("axios");
        var config = {
          method: "get",
          url:
            `https://api.geotalent.co/api/Posts/NewsFeedPagination?userID=11&page=` +
            this.page,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            console.log(response);
            this.items.push(response.data.data);
            console.log(this.items);
          })
          .catch(function(error) {
            console.log(error);
          });
      } else {
        var axios = require("axios");
        var config = {
          method: "get",
          url: `https://api.geotalent.co/api/Userprofile/LoadProfilePagination?profileID=${this.$route.params.id}&page=${this.page}&myID=${this.userID}`,
          headers: {
            Authorization: "bearer " + this.$store.state.userData.token,
          },
        };

        axios(config)
          .then((response) => {
            console.log(response);
            if (response.data.data.posts.length != 0) {
              this.items.push(response.data.data.posts);
            }
            console.log(this.items);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
