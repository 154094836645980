<template>
  <div>
    <b-card v-for="(data, index) in posts" :key="index">
      <div class="d-flex justify-content-start align-items-center mb-1">
        <!-- avatar -->
        <b-avatar :src="data.userPic" size="50" class="mr-1" />
        <!--/ avatar -->
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ data.userName }}
          </h6>
          <!-- <small class="text-muted">{{ data.post.postedOn }}</small> -->
        </div>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="danger" class="ml-auto btn-icon"
          v-b-tooltip.hover.v-danger v-b-tooltip.placement.right title="Delete" size="16"
          @click="Delete(data.post.id, index)">
          <feather-icon size="16" icon="Trash2Icon" />
        </b-button>
      </div>
      <b-card-text>
        {{ data.post.caption }}
      </b-card-text>

      <!-- post img -->
      <b-img v-if="isImage(data.post.cover)" fluid rounded class="mb-25" :src="data.post.cover"
        style="height: 400px; width: 100%; object-fit: contain" />
      <!--/ post img -->
      <!-- post video -->
      <!-- <b-embed
        
        type="iframe"
        :src="data.post.cover"
        allowfullscreen
        class="rounded mb-50"
      /> -->
      <video v-else-if="isVideo(data.post.cover)" type="video" controls style="width: 100%; height: 400px;">
        <source :src="data.post.cover" type="video/mp4" />
      </video>
      <!--/ post video -->

      <!-- likes comments  share-->
      <!-- <b-row class="pb-50 mt-50">
        <b-col
          sm="8"
          class="d-flex justify-content-between justify-content-sm-start mb-2"
        >
          <b-link class="d-flex align-items-center text-muted text-nowrap">
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="data.youLiked ? 'profile-likes':'profile-icon'"
              size="18"
            />
            <span>{{ kFormatter(data.likes) }}</span>
          </b-link>
          <div class="d-flex align-item-center">
            <b-avatar-group
              size="26"
              class="ml-1"
            >
              <b-avatar
                v-for="(avatarData,i) in data.likedUsers"
                :key="i"
                v-b-tooltip.hover.bottom="avatarData.username"
                class="pull-up"
                :src="avatarData.avatar"
              />
            </b-avatar-group>
            <b-link class="text-muted text-nowrap mt-50 ml-50">
              +{{ data.likedCount }} more
            </b-link>
          </div>
        </b-col>
        <b-col
          sm="4"
          class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
        >
          <b-link class="text-body text-nowrap">
            <feather-icon
              icon="MessageSquareIcon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="text-muted mr-1">{{ kFormatter(data.comments) }}</span>
          </b-link>
          <b-link class="text-body text-nowrap">
            <feather-icon
              icon="Share2Icon"
              size="18"
              class="profile-icon mr-50"
            />
            <span class="text-muted">{{ kFormatter(data.share) }}</span>
          </b-link>
        </b-col>
      </b-row> -->
      <!--/ likes comments  share-->

      <!-- comments -->
      <!-- <div
        v-for="(comment, ind) in data.detailedComments"
        :key="ind"
        class="d-flex align-items-start mb-1"
      >
        <b-avatar :src="comment.avatar" size="34" class="mt-25 mr-75" />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              {{ comment.username }}
            </h6>
            <b-link class="text-body">
              <feather-icon
                icon="HeartIcon"
                size="18"
                class="mr-25"
                :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
              />
              <span class="text-muted align-middle">{{
                comment.commentsLikes
              }}</span>
            </b-link>
          </div>
          <small>{{ comment.comment }}</small>
        </div>
      </div> -->
      <!--/ comments -->

      <!-- comment box -->
      <!-- <b-form-group>
        <b-form-textarea rows="3" placeholder="Add Comment" />
      </b-form-group> 

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
      >
        Post Comment
      </b-button>
      -->
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardText,
  BImg,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  VBTooltip,
  BFormTextarea,
  BButton,
  BFormGroup,
  BEmbed,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";

export default {
  components: {
    BAvatar,
    BCard,
    BCardText,
    BButton,
    BFormTextarea,
    BImg,
    BFormGroup,
    BRow,
    BLink,
    BCol,
    BAvatarGroup,
    BEmbed,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    posts: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    console.log(this.posts);
  },
  methods: {
    kFormatter,
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4)$/.test(url);
    },
    Delete(id, index) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Posts/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === "success") {
                this.$bvToast.toast("Post deleted successfully!.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
                console.log(index);
                this.posts.splice(index, 1);
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
